@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
	font-family: Inter, 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

body {
	height: 100vh;
	
	background: hsla(219, 45%, 9%, 1);
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: hsla(219, 45%, 9%, 1);
}

::-webkit-scrollbar-thumb {
	background-color: rgb(34, 37, 44);
	border-radius: 4px;
}


.embla {
	max-width: 48rem;
	margin: auto;
	--slide-height: 19rem;
	--slide-spacing: 1rem;
	--slide-size: 70%;
  }

  .embla__container {
	backface-visibility: hidden;
	display: flex;
	touch-action: pan-y pinch-zoom;
	margin-left: calc(var(--slide-spacing) * -1);
  }


@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }

	.tooltip {
		@apply invisible absolute;
	  }
	  
	.has-tooltip:hover .tooltip {
		@apply visible z-50;
	}
  }